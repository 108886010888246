import { template as template_85b2fa5027544c0fa431b9ebb2c56d7d } from "@ember/template-compiler";
const WelcomeHeader = template_85b2fa5027544c0fa431b9ebb2c56d7d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
