import { template as template_69b2b555ea2246549381e6ebef85a667 } from "@ember/template-compiler";
const FKText = template_69b2b555ea2246549381e6ebef85a667(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
