import { template as template_6fac5837f3ca41359ecce2ccd73609fd } from "@ember/template-compiler";
const FKLabel = template_6fac5837f3ca41359ecce2ccd73609fd(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
