import { template as template_7fd44a558eb341b1b8b33b32d85e4cba } from "@ember/template-compiler";
const FKControlMenuContainer = template_7fd44a558eb341b1b8b33b32d85e4cba(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
